import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "../dynamicCompentencyForms/form_enums";


const responsive_input_fields_span = {
  labelSpan: { xxl: 4, xl: 6, md: 9, sm: 12, xs: 16 },
  inputSpan: { xxl: 12, xl: 12, md: 12, sm: 12, xs: 8 },
  offsetSpan: { xxl: 8, xl: 6, md: 3, sm: 0, xs: 0 },
};

const kps_fields = [
  {
    fillable_by : FillableBy.Evaluator,
    label: 'ACT-K Cartridges:',
    type: "a-input",
    span: 24,
    ...responsive_input_fields_span,
    value: "",
    placeholder: "Enter here..."
  },
  {
    fillable_by : FillableBy.Evaluator,
    label: 'CG8+ Cartridges:',
    type: "a-input",
    span: 24,
    ...responsive_input_fields_span,

    value: "",
    placeholder: "Enter here..."
  },
  {
    fillable_by : FillableBy.Evaluator,
    label: 'CG4+ Cartridges:',
    type: "a-input",
    span: 24,
    ...responsive_input_fields_span,
    value: "",
    placeholder: "Enter here..."
  }
];

  export default {
    fillable_by:FillableBy.Both,
    title:
      "Cognitive i-STAT User Training Competency Assessment",
    sections: [
      {
        type: "questions",
        Layout: 'horizontal',
        fillable_by : FillableBy.Employee,
        fields: [
          {
            type: "a-switch",
            span: 24,
            value: null,
            text: "The i-STAT 1 System analyzer tests both venous and arterial blood samples.",
            isAnswerCorrect:false
          },
          {
            type: "a-switch",
            span: 24,
            value: null,
            text: "When drawing a venous blood sample from the manifold on the NRP circuit, the stopcock on the manifold must remain off to the arterial flow (right-hand side) after the waste until the sampling is complete.",
            isAnswerCorrect:false
          },
          {
            title: "Which of the following are true regarding cartridge usage?",
            type: "a-radio",
            span: 24,
            value: [],
            options: [
              "Cartridges must be at room temperature for 5 minutes prior to use",
              "Air bubbles trapped in the sample well will affect testing",
              "Sample should be dispensed slowly and steadily until it reaches fill line",
              "All of the above",
            ].map((item) => ({ text: item, span: 24 })),
            isAnswerCorrect:false
          },
          {
            type: "a-switch",
            span: 24,
            value: null,
            text: "Cartridges that are kept in the refrigerator at acceptable temperatures expire  at the manufacturer’s expiration date printed on the box.",
            isAnswerCorrect:false
          },
          {
            type: "a-switch",
            span: 24,
            value: null,
            text: "Opened date and expiration dates must be written on both the box of cartridges and individual cartridges left at room temperature.",
            isAnswerCorrect:false
          },
          {
            type: "fields",
            title: 'Cartridges may be stored at room temperature with the expiration date clearly marked on both the outside of the box and individual pouches  how many weeks/months?',
            span: 24,
            field: kps_fields,
            isAnswerCorrect:false
          },
          {
            type: "inline-input",
            span: 24,
            value: [],
            text:"When an individual cartridge is removed from the refrigerator, it must come to room temperature for at least  %input% minutes/hour(s) before using.  When a box of cartridges is removed from the refrigerator, it must come to room temperature for at least  %input% minutes/hour(s) before using.",
            isAnswerCorrect:false
          },
          {
            type: "a-switch",
            span: 24,
            value: null,
            text: "You can remove a cartridge from the analyzer while a test is running and the cartridge locked prompt is displayed.",
            isAnswerCorrect:false
          },
          {
            title: "When using an ACT-K cartridges, How quickly must a sample be run once collected?",
            type: "a-radio",
            span: 24,
            value: [],
            options: [
              "Immediately",
              "within 10 minutes",
            ].map((item) => ({ text: item, span: 24 })),
            isAnswerCorrect:false
          },
          {
            title: "When using a CG8+ or CG4+ cartridge, how quickly must a sample be run once collected as long as it is not exposed to air inside the syringe?",
            type: "a-radio",
            span: 24,
            value: [],
            options: [
              "Immediately",
              "within 10 minutes",
            ].map((item) => ({ text: item, span: 24 })),
            isAnswerCorrect:false
          },
          {
            title: "A result that is flagged with “***” means that:",
            type: "a-radio",
            span: 24,
            value: [],
            options: [
              "The cartridge is defective",
              "Medications may be interfering with the test",
              "You must put the analyzer out of service and call the lab director",
              "A new test should be run with a fresh sample",
              "A, B, and D"
            ].map((item) => ({ text: item, span: 24 })),
            isAnswerCorrect:false
          },
        ]
      },
      {
        type: "scoreInfo",
        Layout: 'horizontal',
        fillable_by : FillableBy.Evaluator,
        fields: [
          {
            type: "inline-input",
            span: 24,
            value: [0, 11],
            text: "Score : %input% / %input%",
          },
          {
            span:24,
            type: "text",
            text: "Passing score is 11/11.  Any questions answered incorrectly were reviewed with trainer/validator and corrected by the user.",
          },
        ]
      },
      {
        title: "Signatures",
        type: "signature",
        fields: getSignatures(FillableBy.Both)
      },
    ]
  }